export const appHead = {"meta":[{"name":"viewport","content":"width=device-width, initial-scale=1"},{"charset":"utf-8"}],"link":[],"style":[],"script":[{"hid":"gtm","children":"(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':\n                  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],\n                  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=\n                  'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);\n                  })(window,document,'script','dataLayer','GTM-MW76NH7G');","type":"text/javascript"},{"src":"https://www.googletagmanager.com/gtag/js?id=G-X1JHZ8BXPR","async":true},{"hid":"gtag","children":"window.dataLayer = window.dataLayer || [];\n                  function gtag(){dataLayer.push(arguments);}\n                  gtag('js', new Date());\n\n                  gtag('config', 'G-X1JHZ8BXPR');","type":"text/javascript"}],"noscript":[{"children":"<iframe src=\"https://www.googletagmanager.com/ns.html?id=GTM-MW76NH7G\"\n                    height=\"0\" width=\"0\" style=\"display:none;visibility:hidden\"></iframe>","type":"text/html","body":true}]}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appRootAttrs = {"id":"__nuxt"}

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const appTeleportAttrs = {"id":"teleports"}

export const renderJsonPayloads = true

export const componentIslands = true

export const payloadExtraction = true

export const cookieStore = true

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink","prefetch":true,"prefetchOn":{"visibility":true}}

export const asyncDataDefaults = {"value":null,"errorValue":null,"deep":true}

export const resetAsyncDataToUndefined = true

export const nuxtDefaultErrorValue = null

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false

export const appId = "nuxt-app"

export const outdatedBuildInterval = 3600000

export const multiApp = false

export const chunkErrorEvent = "vite:preloadError"