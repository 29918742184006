import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as indexLVyUlCMIcmMeta } from "/vercel/path0/pages/projects/life-force/index.vue?macro=true";
import { default as indexZwHwdP9GNgMeta } from "/vercel/path0/pages/projects/with-love/index.vue?macro=true";
export default [
  {
    name: "index___en",
    path: "/en",
    component: () => import("/vercel/path0/pages/index.vue")
  },
  {
    name: "index___nl",
    path: "/nl",
    component: () => import("/vercel/path0/pages/index.vue")
  },
  {
    name: "index___ua",
    path: "/ua",
    component: () => import("/vercel/path0/pages/index.vue")
  },
  {
    name: "projects-life-force___en",
    path: "/en/projects/life-force",
    component: () => import("/vercel/path0/pages/projects/life-force/index.vue")
  },
  {
    name: "projects-life-force___nl",
    path: "/nl/projects/life-force",
    component: () => import("/vercel/path0/pages/projects/life-force/index.vue")
  },
  {
    name: "projects-life-force___ua",
    path: "/ua/projects/life-force",
    component: () => import("/vercel/path0/pages/projects/life-force/index.vue")
  },
  {
    name: "projects-with-love___en",
    path: "/en/projects/with-love",
    component: () => import("/vercel/path0/pages/projects/with-love/index.vue")
  },
  {
    name: "projects-with-love___nl",
    path: "/nl/projects/with-love",
    component: () => import("/vercel/path0/pages/projects/with-love/index.vue")
  },
  {
    name: "projects-with-love___ua",
    path: "/ua/projects/with-love",
    component: () => import("/vercel/path0/pages/projects/with-love/index.vue")
  }
]